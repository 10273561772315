<template>
<div>
  <div id="navtrigger" @click="navtriggerClick" v-bind:class="{selected : menuShow}" tabindex="1" aria-label="Navigation Menu">
    <span class="top"></span>
    <span class="middle"></span>
    <span class="middlecopy"></span>
    <span class="bottom"></span>
  </div>
  <div class="social" tabindex="2">
    <a style="margin-left: 8px;" href="tel:3109235811">310.923.5811</a>
    <a class="social-icon" href="https://www.instagram.com/meredithrealestate/" target="_blank" aria-label="Go to Meredith Instagram">
      <font-awesome-icon :icon="['fab', `instagram`]" />
    </a>
    <a class="social-icon" href="https://www.linkedin.com/in/meredith-schlosser-1167234/" target="_blank" aria-label="Go to Meredith LinkedIn">
      <font-awesome-icon :icon="['fab', `linkedin`]" />
    </a>
    <a class="social-icon" href="https://www.facebook.com/meredithschlosserrealestate/" target="_blank" aria-label="Go to Meredith Facebook">
      <font-awesome-icon :icon="['fab', `facebook-square`]" />
    </a>
    <a class="social-icon" href="mailto:meredithschlosser@gmail.com?subject=Meredith Schlosser&body=I would like to share this with you! https://www.meredithschlosser.com/" aria-label="Contact Meredith">
      <font-awesome-icon :icon="['fas', `share-alt-square`]" />
    </a>
  </div>
  <div id="menuoverlay" :style="menuOverlayHeight" v-bind:class="{active: menuShow}">
    <div class="menuoverlayinner">
      <!-- Main Menu 2020 -->
      <div class="col span1of1">
        <nav id="main">
          <ul>
            <li>
              <router-link to="/" @click.native="menuShow = false">Home</router-link>
            </li>
            <li>
              <div class="expandable">
                <a tabindex="1" aria-label="Expand About Selection"  class="expandable_click" @click.prevent="expand = !expand">About {{expand ? '-' : '+'}}</a>
                <transition name="slide">
                  <ul id="expandable_list" class="expandable_list" v-show="expand">
                    <li>
                      <router-link to="/about" @click.native="menuShow = false">About Meredith Schlosser</router-link>
                    </li>
                    <li>
                      <router-link to="/team" @click.native="menuShow = false">Meredith Schlosser Team</router-link>
                    </li>
                    <li>
                      <a href="https://www.bhhscaproperties.com" target="blank" alt="BHHS">BHHS</a>
                    </li>
                    <hr>
                  </ul>
                </transition>
              </div>
            </li>
            <li>
                <router-link to="/listings" alt="Listings" @click.native="menuShow = false">
                    Listings
                </router-link>
            </li>
            <li>
                <router-link to="/search?boardId=306&boundaryWKT=POLYGON%28%28-118.44546740238144%2034.01052486648203%2C-118.37050859761953%2034.01052486648203%2C-118.37050859761953%2033.948745909378005%2C-118.44546740238144%2033.948745909378005%2C-118.44546740238144%2034.01052486648203%29%29&propertyType=SFR,CND&status=active&sort=priceDesc" alt="Search" @click.native="menuShow = false">
                    Search
                </router-link>
            </li>
            <li>
                <router-link to="/sold" alt="Sold" @click.native="menuShow = false">
                    Sold
                </router-link>
            </li>
            <li>
                <router-link to="/exclusive-guide" alt="Playa Vista Guide" @click.native="menuShow = false">
                  Playa Vista Guide
                </router-link>
            </li>
            <li>
                <router-link to="/testimonials" alt="Testimonials" @click.native="menuShow = false">
                    Testimonials
                </router-link>
            </li>
            <li>
                <router-link to="/press" alt="Press" @click.native="menuShow = false">
                    Press
                </router-link>
            </li>
            <li>
                <router-link to="/contact" alt="Contact" @click.native="menuShow = false">
                    Contact
                </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div id="contact" class="row">
        <div class="col span3of3">
          <router-link to="/contact" aria-label="Go to Meredith Contact Page">
            <font-awesome-icon :icon="['fas', `envelope`]" class="far fa-envelope"/>
          </router-link>
          <a href="mailto:meredithschlosser@gmail.com" title="Email me">meredithschlosser@gmail.com</a> | 310.923.5811
          <div class="socialmediaicons">
            <a href="https://www.facebook.com/meredithschlosserrealestate" aria-label="Go to Meredith Facebook">
              <img src="/images/facebook-2.png" alt="Meredith Facebook">
            </a>
            <a href="https://www.linkedin.com/pub/meredith-schlosser/4/723/116" aria-label="Go to Meredith LinkedIn">
              <img src="/images/linkedin-2.png" alt="Meredith LinkedIn"></a>
            <a href="https://www.instagram.com/meredithrealestate" aria-label="Go to Meredith Instagram">
              <img src="/images/instagram.png" alt="Instagram">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
    name: 'Menu',
    data(){
        return{ 
          menuShow: false,
          menuOverlayHeight: {
            'height': `100vh`
          },
          expand: false,
      }
    },
    methods: {
      navtriggerClick(){
        this.menuShow = !this.menuShow;
      }
    },
    watch:{
      $route (){
        this.menuShow = false;
        this.expand = false;
      }
    },
    created: function(){
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed(){
      window.removeEventListener('scroll', this.handleScroll);
    },
  }
</script>
<style lang="scss" scoped>
.slide-enter-active {
   transition-duration: 0.5s;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   transition-duration: 0.5s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 200px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
